
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import EmptyLayout from './components/layouts/EmptyLayout.vue';

export default defineComponent({
  name: 'App',
  components: {
    EmptyLayout,
  },
  setup() {
    const layout = computed(() => {
      const route = useRoute();

      const layoutTag = (route.meta && route.meta.layout) || 'empty';

      return `${layoutTag}-layout`;
    });

    return {
      layout,
    };
  },
});
