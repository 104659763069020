
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'EmptyLayout',
  data: () => ({
    links: [] as Array<{ to: string; label: string }>,
  }),
  computed: {
    availableLinks(): Array<{ to: string; label: string }> {
      return (
        (this.links && this.links.filter((l) => l.to !== this.$route.fullPath)) || []
      );
    },
  },
});
