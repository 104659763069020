
import { defineComponent } from 'vue';
import {
  formatDate,
  largestTriangleThreeBuckets,
  getSemantleNumber,
} from '@/utils/utils';
import ClipboardJS from 'clipboard';
import * as asciichart from 'asciichart';
import { POSITION, TYPE, useToast } from 'vue-toastification';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import { useStore } from '@/store/store';
Chart.register(...registerables);

export default defineComponent({
  name: 'HomeView',
  components: {
    LineChart,
  },
  data: () => ({
    semantleResult: '',
    imgSrc: '',
    date: formatDate(Date.now()),
    semantleNumber: getSemantleNumber(Date.now()),
    plugin: {
      id: 'semantle_chart_background',
      install: (chart: Chart) => {
        (chart as any).semantleNumber = getSemantleNumber(Date.now());
      },
      beforeDraw: (chart: Chart) => {
        const ctx = chart.canvas.getContext('2d');
        const store = useStore();
        if (ctx) {
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#aaa';
          ctx.strokeRect(0, 0, chart.width, chart.height);
          ctx.fillStyle = '#aaa';
          ctx.textAlign = 'left';
          const bgString = `semantle ${(chart as any).semantleNumber}`;
          ctx.font = '24px sans-serif';
          let size = ctx.measureText(bgString);
          let fs = 24;
          while (size.width > chart.width / 3) {
            ctx.font = `${--fs}px sans-serif`;
            size = ctx.measureText(bgString);
          }
          ctx.fillText(bgString, 10, fs / 2 + 10);
          ctx.fillText(`${store.state.semantle.lines.length} guesses`, 10, fs * 1.5 + 10);
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        }
      },
    },
  }),
  computed: {
    lines(): Array<{ score: number; guess: string; number: number; logScore: number }> {
      const lineStrings = this.semantleResult
        .split('\n')
        .map((l) => l.trim().replaceAll(/\s+/g, ' '))
        .reverse();
      const mappedVals = lineStrings.map((l) => {
        let number = -1;
        let guess = '';
        let score = -101;
        let logScore = -1;
        try {
          const numberMatch = /(^\d+).*$/.exec(l);
          const guessMatch = /^\d+\s+((?:\w+\s)+)(?:\d|\.|-)+.*$/.exec(l);
          const scoreMatch = /^\d+\s+(?:\w+\s)+((?:\d|\.|-)+).*$/.exec(l);

          if (numberMatch && numberMatch.length === 2) {
            number = Number(numberMatch[1]);
          }
          if (guessMatch && guessMatch.length === 2) {
            guess = guessMatch[1];
          }
          if (scoreMatch && scoreMatch.length === 2) {
            score = Number(scoreMatch[1]);
            logScore = score;
          }
        } catch (e) {
          // something happened, oh no
        }
        return {
          score,
          guess,
          number,
          logScore,
        };
      });
      let maxScore = 0;
      return mappedVals
        .filter(
          (l) => l.guess !== '' && l.score >= -100 && l.score <= 100 && l.number > 0
        )
        .sort((a, b) => a.number - b.number)
        .filter((l) => {
          if (maxScore === 100) {
            return false;
          }
          maxScore = l.score;
          return true;
        });
    },
    limitedLines(): Array<{
      score: number;
      guess: string;
      number: number;
      logScore: number;
    }> {
      return [...this.lines]
        .sort((a, b) => a.score - b.score)
        .slice(-100)
        .sort((a, b) => a.number - b.number);
    },
    plot(): string {
      return (
        (this.lines.length &&
          asciichart
            .plot(
              largestTriangleThreeBuckets(
                this.lines.map((l) => l.score),
                20
              ) as number[],
              { height: 5 }
            )
            .replace(/^( +)?(\d|\.|-)+?\s/gm, '')
            .replace(/ +$/gm, '')
            .replace(/ /g, '⠀')) ||
        ''
      );
    },
    shareText(): string {
      return `${this.plot}\nsemantle ${this.semantleNumber}: ${this.lines.length} guesses`;
    },
    chartInstance(): Chart {
      const chartParent = this.$refs.chart as any;
      return chartParent && chartParent.chartInstance;
    },
  },
  methods: {
    async copy() {
      ClipboardJS.copy(document.getElementById('to-copy') as Element, {});
      const toast = useToast();
      toast('copied to clipboard', {
        timeout: 1000,
        position: POSITION.BOTTOM_CENTER,
        draggable: false,
        closeOnClick: false,
        type: TYPE.INFO,
        pauseOnHover: false,
        toastClassName: 'sc-snackbar',
        hideProgressBar: true,
        icon: false,
        closeButton: false,
      });
    },
  },
  watch: {
    async lines() {
      const store = useStore();
      store.commit('semantle/setLines', this.lines);
      await this.$nextTick();
      const image = this.chartInstance.toBase64Image('image/jpg', 1);
      this.imgSrc = image;
    },
  },
});
